var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('div',{staticClass:"h-screen flex flex-column"},[_c('header',{staticClass:"bg-navy-blue text-white flex-none shadow-md",style:({
        backgroundColor: _vm.mainData.header_bg_color,
        color: _vm.mainData.header_font_color,
      })},[_c('div',{staticClass:"container pa-0"},[_c('div',{staticClass:"flex align-center justify-center"},[_c('div',{staticClass:"logo p-2 sm:flex-none hidden-md-and-down"},[_c('router-link',{attrs:{"to":_vm.mainData['knowledge_base'] ? '/knowledge-base' : '/tickets'}},[_c('figure',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.mainData.logoUrl
                      ? _vm.mainData.logoUrl
                      : '@/assets/images/kiva-cloud-logo.png',"alt":""}})])])],1),(_vm.inIframe==false)?_c('nav',{staticClass:"flex-1 mobile-menu-icon p-2 is-mobile"},[_c('v-icon',{class:{ hidden: _vm.searchIsClosed },attrs:{"large":"","color":"white"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" mdi-menu ")])],1):_vm._e(),_c('nav',{staticClass:"flex-1 main-menu p-2 ",class:_vm.inIframe==false ? 'is-desktop':''},[_c('div',{staticClass:"flex align-center relative"},[_c('nav',{staticClass:"flex-1",class:{ hidden: !_vm.searchIsClosed }},[_c('ul',{staticClass:"\n                    menu\n                    flex\n                    items-center\n                    align-center\n                    xs:items-stretch xs:justify-start\n                    space-x-4\n                  "},[_vm._l((_vm.menu),function(item){return [(_vm.userProfile && item.profile.includes(_vm.userProfile) && _vm.mainData[item.visibility])?_c('li',{key:item.path,staticClass:"menu-item"},[_c('router-link',{attrs:{"to":item.path,"exact":""}},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.title)+" ")])])],1):_vm._e()]})],2)]),(!_vm.isHomepage)?_c('form',{staticClass:"search flex justify-end absolute right-0",class:{ 'w-full': !_vm.searchIsClosed },on:{"submit":_vm.handleSearch}},[_c('v-text-field',{staticClass:"expending-search",class:{ closed: _vm.searchIsClosed },attrs:{"dark":"","placeholder":_vm.$t('Search'),"prepend-inner-icon":"mdi-magnify","filled":"","dense":"","hide-details":"","clearable":""},on:{"focus":function($event){_vm.searchIsClosed = false},"blur":_vm.handleSearchBlur},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1):_vm._e()])]),_c('div',{staticClass:"flex-none user-actions p-2 space-x-2 ",class:_vm.inIframe==false ? '':''},[(_vm.isLogin)?[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"text-white",attrs:{"color":"white","dark":""}},'a',attrs,false),on),[_c('v-avatar',{attrs:{"color":"white","size":"41"}},[_c('span',{staticClass:"primary--text text-h5"},[_vm._v(_vm._s(_vm.user.firstChars))])]),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,1198091584)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.handleLogout($event)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("logout")))])],1)],1)],1)]:[(_vm.$route.path != '/sign-in' || !_vm.mainData['knowledge_base'])?_c('v-btn',{staticClass:"default-btn",attrs:{"to":{ name: 'signIn' },"outlined":"","large":""}},[_vm._v(_vm._s(_vm.$t("sign_in"))+" ")]):_c('v-btn',{staticClass:"default-btn",attrs:{"to":'/knowledge-base',"outlined":"","large":""}},[_vm._v(_vm._s(_vm.$t("knowledgebase"))+" ")])]],2)])])]),_c('v-navigation-drawer',{attrs:{"absolute":"","bottom":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.menu),function(item){return (_vm.userProfile && item.profile.includes(_vm.userProfile) && _vm.mainData[item.visibility])?_c('v-list-item',{key:item.path},[(_vm.mainData[item.visibility])?_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.path,"exact":""}},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.title))])])],1):_vm._e()],1):_vm._e()}),1)],1)],1),_c('section',{key:'page'+_vm.language,staticClass:"flex-1 overflow-auto bg-soft-gray height-full"},[_vm._t("default")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }