import Vue from "vue";
import VueRouter from "vue-router";
import { RouteGuardian } from "@/utils/RouteGuardian";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    // component: Home,
    component: () => import("@/views/knowledge-base/index.vue"),
   
  },
  {
    path: "/sign-in",
    name: "signIn",
    component: () => import("@/views/auth/SignIn.vue"),
    meta: {
      guard: {
        only: ["guest"], 
      },
    },
  },
  {
    path: "/knowledge-base",
    name: "knowledgeBase",
    component: () => import("@/views/knowledge-base/index.vue"),
    meta: {
      path: { name: "home" },
    },
  },
  {
    path: "/knowledge-base/:id",
    name: "knowledgeBaseDetail",
    component: () => import("@/views/knowledge-base/detail.vue"),
    meta: {
     
    },
  },
  {
    path: "/sign-up",
    name: "signUp",
    component: () => import("@/views/auth/SignUp.vue"),
    meta: {
      guard: {
        only: ["guest"],
        path: { name: "home" },
      },
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/Search.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/tickets",
    name: "tickets",
    component: () => import("@/views/Tickets.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/requests",
    name: "requests",
    component: () => import("@/views/Requests.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/events",
    name: "events",
    component: () => import("@/views/events/Events"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/events/:id",
    name: "events.detail",
    component: () => import("@/views/events/EventDetail"),
    props: true,
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/pages",
    name: "pages",
    component: () => import("@/views/pages/Pages.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/files",
    name: "files",
    component: () => import("@/views/files/Files.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("@/views/projects/Projects.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/tasks/Tasks.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/tasks/:id",
    name: "taskDetail",
    component: () => import("@/views/tasks/TasksDetail.vue"),
    props: true,
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/project/:id",
    name: "projectDetail",
    component: () => import("@/views/projects/ProjectDetail.vue"),
    props: true,
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/project/:id/print",
    name: "projectDetail",
    component: () => import("@/views/projects/ProjectPrint.vue"),
    props: true,
    
    meta: {
      guard: { except: ["guest"] },
      layout: 'PrintLayout',
    },
  },
  {
    path: "/tickets/create",
    name: "createTicket",
    component: () => import("@/views/CreateTicket.vue"),
    props: true,
    meta: {
      guard: { except: ["guest"] } 
      
    },
  },
  {
    path: "/tickets/:id",
    name: "ticketDetail",
    component: () => import("@/views/TicketDetail.vue"),
    props: true,
    meta: {
      guard: { except: ["guest"] },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const RG = new RouteGuardian({
  guardRoute: {
    name: "signIn",
  },
});

router.beforeEach((to, from, next) => {
  const role = store.getters["account/getUserRole"] || "guest";
  RG.setRole({ role })
    .handler(to, from, next)
    .then(() => {
      next();
    })
    .catch(next);
});

export default router;
