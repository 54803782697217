import Vue from "vue";

Vue.filter("shortenFileName", (value,max) => {

  let l =  value && value != '' ? value.length : 0 ;
  if(l>20){

     let s = value.substring(0, 11);
     let e = value.substring(value.length - 7, value.length);
      return s+'...'+e
  }else{

    return value
  }

} 
);
