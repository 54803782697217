import { TOKEN, USER } from "./mutation-types";
import { AuthProxy } from "@/proxies/AuthProxy";
import userParser from "@/store/modules/account/utils/userParser";
import { LogoutProxy } from "@/proxies/LogoutProxy";

export default {
  async login({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      new AuthProxy()
        .create({ item: payload })
        .then(({ token }) => {
          if (token) {
            dispatch("setToken", token).then(() => {
              resolve();
            });
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  logout({ commit,state }) { 
    // console.log("state.token",state.token)
    if(state.token){
      return new Promise((resolve, reject) => { 
        new LogoutProxy() 
        .create({})
        .then((response) => { 
          if(response.success){
            commit(TOKEN, "");
            commit(USER, null);
            resolve(true);
          } 
        })
        .catch((response)=>{
          commit(TOKEN, "");
          commit(USER, null);
          resolve(true);
        })  
      });
    }else{
      commit(TOKEN, "");
      commit(USER, null);
      resolve(true);
    }
  },
  setToken({ commit }, token) {
    return new Promise((resolve) => {
      commit(TOKEN, token);
      commit(USER, userParser(token));
      resolve();
    });
  },
};
