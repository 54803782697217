import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";
import Quill from "quill";
import quillMention from "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";

Quill.register("modules/quillMention", quillMention);

Vue.use(VueQuillEditor, {});
