export default {
  sign_up: "Sign Up",
  sign_in: "Sign In",
  knowledgebase: "Knowledge Base",
  KnowledgeBase: "Knowledge Base",
  logout: "Logout",
  'Request Status': "Request Status",
  'Show All': "Show All",
  'Minimize': "Gizle",
  'Kiva Customer Portal': "Kiva Customer Portal",
  '+ Attach files to this message': "+ Attach files to this message",
  Email: "Email",
  Brand: "Brand",
  Password: "Password",
  Search: "Search",
  Click: "Click",
  Week: "Week",
  Past:'Past',
  Next:'Next',
  'Back to list': "Back to list", 
  'Tasks in Progress':'Tasks in Progress',
  'Tasks in Backlog':'Tasks in Backlog',
  'Open Threads':'Open Threads',
  'Open Tasks':'Open Tasks',
  'Team':'Team',
  Prev:'Prev',
  'Upcoming Milestone':'Upcoming Milestone',
  'Last Message':'Last Message',
  Date:'Date',
  'Project Details':'Project Details',
  'Set Today':'Set Today',
  'Status Report':'Status Report',


};
