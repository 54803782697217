export default {
  sign_up: "Kayıt ol",
  knowledgebase: "Bilgi Bankası",
  knowledgeBase: "Bilgi Bankası",
  KnowledgeBase: "Bilgi Bankası",
  sign_in: "Giriş Yap",
  logout: "Çıkış Yap",
  Home: "Ana Sayfa",
  Tickets: "Destek Talepleri",
  Tasks: "Görevler",
  Pages: "Dokümanlar",
  Files: "Dosyalar",
  Projects: "Projeler",
  Search: "Arama",
  'Show All': "Tümünü Göster",
  'Minimize': "Gizle",
  Email: "E-posta",
  Password: "Şifre",
  'Request Status': "Talep Durumu",

  'Sections' : 'Bölümler',
  'New' : 'Yeni',
  'Feasibility' : 'İhtiyaç Analizi',
  'Waiting for Budget and Project Plan' : 'Proje Planı ve Bütçe Bekleniyor',
  'Waiting for Customer Approval' : 'Müşteri Onayı Bekleniyor',
  'Approved' : 'Onaylandı',
  'In Progress' : 'Devam Ediyor',
  'Completed' : 'Tamamlandı',
  'Cancelled' : 'İptal Edildi',
  'Unqualified' : 'Satış Fırsatı Yok',
  'Converted to Lead' : 'Fırsata Dönüştürüldü',

  "Create Ticket": "Destek Talebi Oluştur",
  "Approval": "Onayla",
  "Request Revision": "Revizyon İste", 
  "Cancel Request": "Talebi İptal Et", 
  "New Request": "Yeni Talep",
  "New Requests": "Yeni Talepler",
  "Create New Request": "Yeni Talep Oluştur",
  Open: "Açık",
  My: "Benim",
  Waiting: "Bekleyen",
  Closed: "Kapanan",
  "Select date range": "Tarih aralığını seç",
  Priority: "Öncelik",
  Urgent: "Acil",
  High: "Yüksek Öncelikli",
  Medium: "Orta",
  Low: "Düşük",
  Categories: "Kategori",
  Subject: "Konu",
  Status: "Durum",
  Created: "Oluşturma",
  "Last Update": "Son Güncelleme",
  "Back to Tickets": "Destek Taleplerine Geri Dön",
  Settings: "Ayarlar",
  Cancel: "iptal",
  Reply: "Yanıt",
  Send: "Gönder",
  All: "Tümü",
  Brand: "Marka",
  "New Ticket": "Yeni Destek Talebi",
  "New / Change Request": "Yeni Talepler ve Değişiklik Talepleri",
  Category: "Kategori",
  "Sub Category": "Alt Kategori",
  Description: "Açıklama",
  "Group By": "Grupla",
  "Due Date": "Son Tarih",
  None: "Hiçbiri",
  "Record Owner": "Kayıt Tarihi",
  Owner: "Sahibi",
  "Back to Tasks": "Görevlere geri dön",
  Project: "Proje",
  Section: "Bölüm",
  "Assigned To": "Atanan",
  Label: "Etiket",
  Document: "Dosya",
  "Sub Tasks": "Alt görevler",
  "Write a comment...": "Yorum yaz...",
  Name: "Adı",
  "Last Revision": "Son Revizyon",
  "Back to Pages": "Dokümanlara Geri Dön",
  "Created by:": "Oluşturan",
  "Content is empty.": "İçerik boş.",
  "Document Name": "Dosya Adı",
  Type: "Türü",
  "Created by": "Oluşturan",
  Action: "Aksiyon",
  'Back to list': "Listeye Dön",
  "Kiva Customer Portal": "KivaCRM Müşteri Portalı",
  "+ Attach files to this message":
    "+ Mesajınıza Dosya(lar) eklemek için tıklayın",
  Click: "Tıkla",
  Week: "Hafta",
  Past: "Geçmiş",
  Next: "Gelecek",
  "Tasks in Progress": "Tasks in Progress",
  "Tasks in Backlog": "Tasks in Backlog",
  "Open Threads": "Açık Destekler",
  "Open Tasks": "Açık Görevler",
  Team: "Takım",
  Prev: "Önceki",
  "Upcoming Milestone": "Yaklaşan Önemli Tarihler",
  "Last Message": "Son Mesaj",
  Date: "Tarih",
  "Project Details": "Proje Detayları",
  "Set Today": "Bugün",
  "Status Report": "Status Report",
  save: "Kaydet",
};
