export class RouteGuardian {
  constructor ({ guardRoute, role }) {
    this.role = role
    this.guardRoute = guardRoute
  }

  setRole ({ role }) {
    this.role = role
    return this
  }

  handler (to) {
    return new Promise((resolve, reject) => {
      if (to.matched.some(r => r.meta.guard)) {
        const condition = this.conditionBuilder(to.matched)
        if (condition.only.length || condition.except.length) {
          const path = (to.meta.guard && to.meta.guard.path) || this.guardRoute
          let guardRoute
          if (condition.only.length) {
            if (condition.only.includes(this.role)) {
              resolve()
            } else {
              guardRoute = path
            }
          } else if (condition.except.length) {
            if (condition.except.includes(this.role)) {
              guardRoute = path
            } else {
              resolve()
            }
          }
          reject(guardRoute)
        } else {
          resolve()
        }
      } else {
        resolve()
      }
    })
  }

  conditionBuilder (matched) {
    return matched.reduce((acc, current) => {
      const currentRoute = current.meta.guard
      if (currentRoute) {
        if (Array.isArray(currentRoute.only)) {
          acc.only = [...acc.only, ...currentRoute.only]
        } else if (typeof currentRoute.only === 'string') {
          acc.only.push(currentRoute.only)
        }
        if (Array.isArray(currentRoute.except)) {
          acc.except = [...acc.except, ...currentRoute.except]
        } else if (typeof currentRoute.except === 'string') {
          acc.except.push(currentRoute.except)
        }
      }
      return acc
    }, { only: [], except: [] })
  }
}
