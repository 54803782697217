export default {
  getCategories(state) {
    return state.categories;
  },
  getRequestStatus(state) {
    return state.categories;
  },
  getProjects(state) {
    return state.projects;
  },
  getProjectById(state) {
    return (id) => state.projects.find((p) => p.record_id === id);
  },
  getUsers(state) {
    return state.users;
  },
  getSections(state) {
    return state.sections;
  },
  getSectionsByProject(state) {
    return (id) => state.sections.find((p) => p.project === id);
  },
  getSectionsGrouped(state) {
    return state.sectionsGrouped;
  },
};
