const CATEGORIES = "CATEGORIES";
const PROJECTS = "PROJECTS";
const MAIN = "MAIN";
const USERS = "USERS";
const SECTIONS = "SECTIONS";
const SECTIONS_GROUPED = "SECTIONS_GROUPED";
const PAGES = "PAGES";
const LANGUAGE = "LANGUAGE";

 
export { CATEGORIES, PROJECTS, USERS, SECTIONS, SECTIONS_GROUPED,MAIN,PAGES,LANGUAGE };
