import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import tr from "vuetify/lib/locale/tr";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2887eb",
        secondary: "#1bc5bd",
        accent: "#ffa800",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { tr },
    current: "tr",
  },
});
import VuetifyConfirm from "vuetify-confirm";

Vue.use(VuetifyConfirm, { vuetify });

export default vuetify
