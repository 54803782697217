import * as types from "./mutation-types";

export function createAccountPlugin({ namespace = "account" } = {}) {
  function getType(type) {
    return `${namespace}/${type}`;
  }

  return function (store) {
    store.subscribe(({ type, payload: token }) => {
      switch (type) {
        case getType(types.TOKEN): {
          if (token !== undefined) {
            localStorage.setItem(types.TOKEN, token);
          }
          break;
        }
      }
    });
  };
}
