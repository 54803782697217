export default function (jwt) {
  if (jwt) {
    let user = JSON.parse(atob(jwt.split('.')[1]))
    let  u = user.name.split(" ")
    let f1 = u[0].charAt(0);
    let f2 = u[1] ?  u[1].charAt(0) : "";
    return {
      id: user.id,
      iss: user.iss,
      role: user.role || 'user',
      exp: user.exp,
      acl: user.acl,
      name: user.name,
      firstChars: f1+""+f2,
    }
  }
}
