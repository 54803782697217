<template>
  <v-app class="kiva-customer-portal">
    <v-progress-linear
      class="global-loader"
      color="primary"
      :active="isLoading"
      indeterminate
      absolute
      bottom
      height="4"
    ></v-progress-linear>

      <component :is="getLayout">
          <router-view></router-view>
      </component>
  </v-app>
</template>
<script>
import AppLayout from '@/layouts/AppLayout' 
import PrintLayout from '@/layouts/PrintLayout'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: { AppLayout,PrintLayout },
  data () {
    return {
      initialized: false
    }
  },
  created(){
    if(window){
      window.document.title = this.$t('Kiva Customer Portal');
    }
  },
  methods: {
    ...mapActions('common', ['categories', 'projects', 'users']),
   async  init () {

      if(this.isLogin){
        Promise.all([
          this.categories(),
          this.projects(),
          this.users()
        ])
        .then(() => {
          this.initialized = true
        })
      }
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('account', ['isLogin']),
    getLayout(){
        let meta = this.$route.meta; 
        return meta.layout ? meta.layout :  'AppLayout';
    },
     
  },
  watch: {
    isLogin: {
      immediate: true,
      handler (val) {
        if (!val) {
  
            // this.$router.push({ name: 'home', query })
        } else {
          if (!this.initialized) {
            this.init()
          }else{
            //  this.$router.push({ name: 'home' })
          }
        }
      }
    },
  },
}
</script>
