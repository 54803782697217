import Vue from "vue";
import Vuex from "vuex";
import { INCREMENT_LOADING, DECREMENT_LOADING } from "./mutation-types";
import { account, accountPlugin } from "@/store/modules/account";
import { common } from "@/store/modules/common";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: 0,
    filters:{
      request:{
        page: 1,
        rowsPerPage: 50,
        sort: {
          name: ['record_id'],
          desc: [false],
        },
        requestStatus:[],
        projectId: [],
        caseStatus: '1',
        priority: [],
        keyword: '',
        dates: [],
        brand:[]
      },
      tickets:{
        page: 1,
        rowsPerPage: 50,
        sort: {
          name: ['record_id'],
          desc: [false],
        },
        projectId: [],
        caseStatus: '1',
        priority: [],
        keyword: '',
        dates: [],
        brand:[]
      }
    }
  },
  mutations: {
    [INCREMENT_LOADING](state) {
      state.loading = state.loading + 1;
    },
    [DECREMENT_LOADING](state) {
      state.loading = state.loading - 1;
    },
  },
  getters: {
    isLoading(state) {
      return !!state.loading;
    },
  },
  actions: {},
  modules: {
    account,
    common,
  },
  plugins: [accountPlugin],
});
