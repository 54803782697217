import { BaseProxy } from "@/proxies/BaseProxy";

export class CategoryProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `categories`,
      parameters,
    });
  }
}
