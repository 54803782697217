export default {
  getToken: (state) => {
    return state.token;
  },
  isLogin(state) {
    return !!(state.token && state.user);
  },
  userProfile(state) {
    return state.token && state.user.acl ? state.user.acl : "";
    // return 'MEMBER'
  },
  getUserRole(state) {
    if (state.user) {
      return state.user.role || "user";
    } else {
      return "guest";
    }
  },
  getUserName(state) {
    return state.user?.name;
  },
  currentUser(state) {
    if (state.user) {
      return state.user;
    }
  },
};
