import { CATEGORIES, PROJECTS, USERS, SECTIONS, SECTIONS_GROUPED,MAIN,PAGES,LANGUAGE } from './mutation-types'

export default {
  [CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [PROJECTS](state, projects) {
    state.projects = projects;
  },
  [MAIN](state, main) {
    state.main = main;
  },
  [PAGES](state, pages) {
    state.pages = pages;
  },
  [USERS](state, users) {
    state.users = users;
  },
  [SECTIONS](state, users) {
    state.sections = users;
  },
  [SECTIONS_GROUPED](state, users) {
    state.sectionsGrouped = users;
  },
  [LANGUAGE](state, language) {
    state.language = language;
  },
};
