<template>
   <div>
        <slot></slot>
   </div>  
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'PrintLayout',
  created(){
    this.starter()
  },
  data () {
    return {
      
    }
  },
 
  methods: {
    starter(){
       this.$store.dispatch("common/categories",{}); 
      this.$store.dispatch("common/projects",{});
    },
    handleSearchBlur () {
      if (!this.searchQuery) {
        this.searchIsClosed = true
      }
    } 
  },
  computed: {
    isHomepage () {
      return this.$route.name === 'home'
    },
    ...mapGetters('account', ['isLogin']),
    ...mapGetters('account', ['userProfile']),
    ...mapState("account", ["token"]),

  },
  watch:{
    token(val){
      if(val){
        this.starter();
      }
    }
  }
}
</script>
<style lang="scss">
 
.print-page {
 width: 100%;
}
 
</style>
