<template>
  <v-main>
    <div class="h-screen flex flex-column">
      <header
        class="bg-navy-blue text-white flex-none shadow-md"
        :style="{
          backgroundColor: mainData.header_bg_color,
          color: mainData.header_font_color,
        }"
      >
        <div class="container pa-0">
          <div class="flex align-center justify-center">
            <div class="logo p-2 sm:flex-none hidden-md-and-down">
              <router-link :to="mainData['knowledge_base'] ? '/knowledge-base' : '/tickets'">
                <figure class="logo">
                  <img
                    :src="
                      mainData.logoUrl
                        ? mainData.logoUrl
                        : '@/assets/images/kiva-cloud-logo.png'
                    "
                    alt=""
                  />
                </figure>
              </router-link>
            </div>

       
            <nav class="flex-1 mobile-menu-icon p-2 is-mobile" v-if="inIframe==false">
              <v-icon
                large
                @click.stop="drawer = !drawer"
                color="white"
                :class="{ hidden: searchIsClosed }"
              >
                mdi-menu
              </v-icon>
            </nav>
            <nav class="flex-1 main-menu p-2 " :class="inIframe==false ? 'is-desktop':''">
              <div class="flex align-center relative">
                <nav class="flex-1" :class="{ hidden: !searchIsClosed }">
                  <ul
                    class="
                      menu
                      flex
                      items-center
                      align-center
                      xs:items-stretch xs:justify-start
                      space-x-4
                    "
                  >
                    <template v-for="item in menu">
                      <li
                        class="menu-item"
                        :key="item.path"
                        v-if="userProfile && item.profile.includes(userProfile) && mainData[item.visibility]"
                      >
                        <router-link :to="item.path" exact>
                          <i :class="item.icon"></i>
                          <span>{{ item.title }}  </span>
                        </router-link>
                      </li>
                    </template>
                  </ul>
                </nav>
                <form
                  v-if="!isHomepage"
                  class="search flex justify-end absolute right-0"
                  :class="{ 'w-full': !searchIsClosed }"
                  @submit="handleSearch"
                >
                  <v-text-field
                    @focus="searchIsClosed = false"
                    @blur="handleSearchBlur"
                    dark
                    v-model="searchQuery"
                    :placeholder="$t('Search')"
                    prepend-inner-icon="mdi-magnify"
                    class="expending-search"
                    :class="{ closed: searchIsClosed }"
                    filled
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </form>
              </div>
            </nav>
            <div
              class="flex-none user-actions p-2 space-x-2 " :class="inIframe==false ? '':''"
            >
               
              <template v-if="isLogin"> 
                <v-menu bottom right  >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      color="white"
                      class="text-white"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar color="white" size="41">  <span class="primary--text text-h5">{{user.firstChars}}</span> </v-avatar>
                      <!-- {{ user.name  }}  -->
                      <v-icon dark right> mdi-chevron-down </v-icon>
                    </a>
                  </template>

                  <v-list>
                    <v-list-item @click.prevent="handleLogout">
                      <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                <!-- ...  {{$route.path}} -->
                <v-btn
                  v-if="$route.path != '/sign-in' || !mainData['knowledge_base']"
                  class="default-btn"
                  :to="{ name: 'signIn' }"
                  outlined
                  large
                  >{{ $t("sign_in") }}
                </v-btn>
                <v-btn
                  v-else
                  class="default-btn"
                  :to="'/knowledge-base'"
                  outlined
                  large
                  >{{ $t("knowledgebase") }}
                </v-btn>
              </template>
            </div>
          </div>
        </div>
      </header>
      <v-navigation-drawer v-model="drawer" absolute bottom temporary>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="item in menu" :key="item.path" v-if="userProfile && item.profile.includes(userProfile) && mainData[item.visibility]">
              <v-list-item-title v-if="mainData[item.visibility]">
                <router-link :to="item.path" exact   >
                  <i :class="item.icon"></i>
                  <span>{{ item.title }}</span>
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
 
      <section class="flex-1 overflow-auto bg-soft-gray height-full" :key="'page'+language">
        <slot></slot>
      </section>
      <!-- <footer class="app-footer shadow-md flex align-center">
        Kiva.app Customer Portal
        <a href="https://kiva.cloud" target="_blank"
        ><img src="@/assets/images/kv-logo.png" class="pr-1 pl-1"
        /></a>
        by
        <a href="https://kiva.cloud" target="_blank" class="pl-1">
          Kiva.cloud</a
        >
      </footer> -->
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "AppLayout",
  created() {
    this.starter();
		if(this.route_page=='knowledgeBase'){
				this.$router.push("/knowledge-base")
		}else if(this.route_page=='supportTickets'){
			this.$router.push("/tickets")
		}
  },
  data() {
    return {
      searchIsClosed: true,
      searchQuery: "",
      drawer: false,
      group: null,
      menu: [
        // {
        //   icon: 'fal fa-home',
        //   title: this.$t('Home'),
        //   path: '/',
        //   profile:['MANAGER','MEMBER']
        // },
        {
          icon: "fal fa-books",
          title: this.$t("KnowledgeBase"),
          path: "/knowledge-base",
          profile: ["MANAGER", "MEMBER"],
          visibility:'knowledge_base'
        },
        {
          icon: "fal fa-inbox",
          title: this.$t("Tickets"),
          path: "/tickets",
          profile: ["MANAGER", "MEMBER"],
          visibility:'support_cases'
        },
        {
          icon: "fal fa-inbox",
          title: this.$t("New Requests"),
          path: "/requests",
          profile: ["MANAGER", "MEMBER"],
          visibility:'new_change_requests'
        },
        {
          icon: "fal fa-tasks",
          title: this.$t("Tasks"),
          path: "/tasks",
          profile: ["MANAGER"],
            visibility:'tasks'
        },
        {
          icon: "fal fa-file-alt",
          title: this.$t("Pages"),
          path: "/pages",
          profile: ["MANAGER"],
           visibility:'documents'
        },
        {
          icon: "fal fa-folders",
          title: this.$t("Files"),
          path: "/files",
          profile: ["MANAGER"],
           visibility:'files'
        },
        {
          icon: "fal fa-building",
          title: this.$t("Projects"),
          path: "/projects",
          profile: ["MANAGER"],
           visibility:'projects'
        },
      ],
    };
  },

  methods: {
    catchLanguage(){
        if(this.$route.query.language){ 
           this.$store.state.common.language =  this.$route.query.language=="tr" ? "tr" : 'en';
           localStorage.setItem("language", this.$store.state.common.language); 
      }else{
          let l = localStorage.getItem("language");
           this.$store.state.common.language = l ? l : 'en';
      }
    },
    async starter() {

      this.catchLanguage();

      this.$store.dispatch("common/main", {});
      if (this.isLogin) {
        this.$store.dispatch("common/categories", {});
        this.$store.dispatch("common/projects", {});
      }
    },
    handleSearchBlur() {
      if (!this.searchQuery) {
        this.searchIsClosed = true;
      }
    },
    handleSearch(e) {
      e.preventDefault();
      this.$router.push({
        name: "search",
        query: { term: this.searchQuery },
      });
    },
    handleLogout() {
   
      this.logout().then(() => {
        if(this.mainData['knowledge_base']){
          this.$router.push({ name: "knowledgeBase" });
        }else{
          this.$router.push({ name: "signIn" }); 
        }
      });
    },
    ...mapActions("account", ["logout"]),
  },
  computed: {
   inIframe () {
        try {
            return window.self !== window.top;
            // return true;
        } catch (e) {
            return true;
        }
    },
    language(){
      return this.$store.state.common.language 
    },
    route_language(){
      return this.$route.query.language 
    },
    route_page(){
      return this.$route.query.redirectPage
			// redirectPage?knowledgeBase
    },
    isHomepage() {
      return this.$route.name === "home";
    },
    ...mapGetters("account", ["isLogin"]),
    ...mapGetters("account", ["userProfile"]),
    ...mapState("account", ["token"]),
    ...mapState("account", ["user"]),
    mainData() {
      return this.$store.state.common.main;
    },
  },
  watch: {
    token(val) {
      if (val) {
        this.starter();
      }
    }, 
    route_language(val) {
      if (val) {
        this.catchLanguage();
      }
    }, 
    mainData(val) {
      if (val) {
        document.documentElement.style.setProperty(
          "--navy-blue",
          "#" + val.header_bg_color
        );
        document.documentElement.style.setProperty(
          "--v-primary-base",
          "#" + val.primary_button_color
        );
        document.documentElement.style.setProperty(
          "--v-primary-base-light",
          "#" + val.primary_button_color+'33'
        );
        document.documentElement.style.setProperty(
          "--v-header-color",
          "#" + val.header_font_color
        );

        document.title = val.portal_name ? val.portal_name  : 'Home Page'
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = val.faviconUrl;
      }
    },
  },
};
</script> 
<style lang="scss">
.logo {
  img {
    height: 30px;
  }
}
.v-btn {
   text-transform: none!important;
}
.default-btn {
  border-color: var(--v-header-color) !important;
  color: var(--v-header-color) !important;
}
.mobile-menu-icon {
  text-align: right;
  cursor: pointer;
}

.menu {
  .menu-item {
   
    a {
      margin-right: 10px;
       position: relative;
      color: #fff;
      display: flex;
      align-content: center;
      justify-content: center;
      font-size: 15px;
      white-space: nowrap;
      &.router-link-active,
      &:hover {
        // color: #65b4fb;

        i {
          // color: #65b4fb;
          vertical-align: middle;
        }
        &:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }

      i {
        color: #fff;
        margin-right: 0.5rem;
        font-size: 16px;
        vertical-align: middle;
      }
    }
  }
}

.search {
  .v-input.expending-search {
    transition: max-width 0.3s;

    &.closed {
      max-width: 46px;

      .v-input__slot {
        &:before,
        &after {
          border-color: transparent;
        }
      }
    }
  }
}

.app-footer {
  padding: 10px 0;
  display: flex;
  font-size: 0.75rem;
  align-content: center;
  justify-content: center;
  height: 50px;
  max-height: 50px;
  display: none;
}
a.text-white {
  color: #fff !important;
}

</style>
