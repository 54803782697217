import Vue from "vue";
import qs from "qs";
import App from "./App.vue";
//import "./plugins/registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./assets/styles/app.css";
import "@/packages/redactor";
import "@/plugins/vue-quill";
import "@/filters";
import "@/assets/icons"; // icon
import "./vendors/fontawesome/css/light.min.css"; // icon
import "./vendors/fontawesome/css/duotone.min.css"; // icon
import "./vendors/fontawesome/css/fontawesome.min.css"; // icon
import '@/plugins/v-viewer'

// devextreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
});
const query = qs.parse(window.location.search, {
  plainObjects: true,
  ignoreQueryPrefix: true,
});
if (query.token) {
  store.dispatch("account/setToken", query.token).then(() => {
    delete query.token;
    history.replaceState(
      null,
      null,
      qs.stringify(query, { plainObjects: true })
    );
    app.$mount("#app");
  });
} else {
  app.$mount("#app");
}
