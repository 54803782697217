import { client } from "@/utils/request";
import qs from "qs";

const datableTypes = ["post", "put", "patch"];

export class BaseProxy {
  constructor({ endpoint, parameters = {}, config = {} }) {
    this.$http = client;
    this.endpoint = endpoint;
    this.parameters = parameters;
    this.config = config;
  }

  addPath(path) {
    this.endpoint = `${this.endpoint}/${path}`;
    return this;
  }

  setHeaders(parameters) {
    this.config.headers = { ...this.config.headers, ...parameters };
    return this;
  }

  setHeader(key, value) {
    this.config.headers = { ...this.config.headers, [key]: value };
    return this;
  }

  setParameters(parameters) {
    Object.keys(parameters).forEach(key => {
      this.parameters[key] = parameters[key];
    });

    return this;
  }
  setFields (array) {
    this.setParameter('fields', JSON.stringify(array))
    return this
  }
  setParameter(parameter, value) {
    this.parameters[parameter] = value;
    return this;
  }

  setParameter(parameter, value) {
    this.parameters[parameter] = value;
    return this;
  }
  removeParameters(parameters) {
    parameters.forEach(parameter => {
      delete this.parameters[parameter];
    });

    return this;
  }

  removeParameter(parameter) {
    delete this.parameters[parameter];

    return this;
  }

  submit(requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      let args = [url + this.getParameterString(), this.config];
      if (datableTypes.includes(requestType)) {
        args.splice(1, 0, data);
      }
      if (requestType === "patch") {
        args[2] = {
          headers: { "content-type": "application/json-patch+json" }
        };
      }
      this.$http[requestType](...args)
        .then(response => {
          resolve(response.data);
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data);
          } else {
            reject(response);
          }
        });
    });
  }

  all() {
    return this.submit("get", `/${this.endpoint}`);
  }

  find({ id }) {
    return this.submit("get", `/${this.endpoint}/${id}`);
  }

  create({ item }) {
    return this.submit("post", `/${this.endpoint}`, item);
  }

  update({ id, item } = {}) {
    if (id) {
      return this.submit("put", `/${this.endpoint}/${id}`, item);
    }
    return this.submit("put", `/${this.endpoint}`, item);
  }

  patch({ id, item } = {}) {
    if (id) {
      return this.submit("patch", `/${this.endpoint}/${id}`, item);
    }
    return this.submit("patch", `/${this.endpoint}`, item);
  }

  destroy({ id }) {
    if (id) {
      return this.submit("delete", `/${this.endpoint}/${id}`);
    }
    return this.submit("delete", `/${this.endpoint}`);
  }

  getParameterString() {
    const parameterStrings = qs.stringify(this.parameters, {
      encodeValuesOnly: true
    });
    return parameterStrings.length === 0 ? "" : `?${parameterStrings}`;
  }
}
