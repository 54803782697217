import axios from "axios";
import store from "@/store";
import i18n from "@/plugins/i18n";
import { DECREMENT_LOADING, INCREMENT_LOADING } from "@/store/mutation-types";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/support/client/`,
  headers: {}
});

let requestInterceptors = [
  [
    function(config) {
      store.commit(INCREMENT_LOADING);
      const token = store.getters["account/getToken"];
      config.headers.common["accept-language"] = i18n.locale;
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(error) {
      store.commit(DECREMENT_LOADING);
      return Promise.reject(error);
    }
  ]
];

let responseInterceptors = [
  [
    function(response) {
      store.commit(DECREMENT_LOADING);
      return response;
    },
    function(error) {
      store.commit(DECREMENT_LOADING);
      const { status } = error.response;
      if (parseInt(status, 10) === 401) {
        store.dispatch("account/logout");
      } else {
        //error handling
      }
      return Promise.reject(error);
    }
  ]
];

responseInterceptors.forEach(interceptor => {
  client.interceptors.response.use(...interceptor);
});

requestInterceptors.forEach(interceptor => {
  client.interceptors.request.use(...interceptor);
});

export { client };
