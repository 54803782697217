export default {
  categories: [],
  projects: [],
  main: {},
  pages: [],
  language:'en',
  pagesLoaded: false,
  users: [],
  sections: [],
  sectionsGrouped: [],
};
